import {
  View,
  Text,
  StyleSheet,
  TextInput,
  SafeAreaView,
  Button,
} from "react-native";
import React, { useState } from "react";
import { auth, db } from "../../../firebase";
import { collection, doc, addDoc, updateDoc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

export default function Dashboard() {
  const [gymName, setGymName] = useState("");
  const [gymPhone, setGymPhone] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerPassword, setOwnerPassword] = useState("");
  const [gymId, setGymId] = useState("");
  const [gymMessage, setGymMessage] = useState("");
  const [ownerMessage, setOwnerMessage] = useState("");

  const gymData = { name: gymName, phone_number: gymPhone, owners: [] };
  const ownerData = {
    email: ownerEmail,
    fullName: ownerName,
    phone_number: ownerPhone,
    user_role: "owner",
    gyms: [gymId],
  };

  const addGymToDB = () => {
    addDoc(collection(db, "gyms"), gymData)
      .then((data) => {
        setGymId(data.id);
        setGymMessage("Gym Added to DB");
      })
      .catch(console.log);
  };

  const addUserToDB = () => {
    createUserWithEmailAndPassword(auth, ownerEmail, ownerPassword).then(
      (userData) => {
        setDoc(doc(db, "users", userData.user.uid), ownerData)
          .then((userDocument) => {
            setOwnerMessage("Owner Added to DB");
            updateDoc(doc(db, "gyms", gymId), {
              owners: [userData.user.uid],
            })
              .then((gymDoc) => {
                setOwnerMessage("Owner Added to DB, and connected with Gym ");
              })
              .catch(console.log);
          })
          .catch(console.log);
      }
    );
  };

  return (
    <SafeAreaView>
      <View
        style={{
          width: 720,
          maxWidth: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 128,
          borderRadius: 6,
          backgroundColor: "#ffffff",
          paddingHorizontal: 32,
          paddingVertical: 24,
        }}
      >
        <Text style={{ textAlign: "center", marginBottom: 32 }}>
          Add new Gym
        </Text>
        <Text style={{ textAlign: "center", marginBottom: 14 }}>
          {gymMessage}
        </Text>
        <TextInput
          style={styles.input}
          placeholder="Gym name"
          value={gymName}
          onChangeText={(value) => {
            setGymName(value);
          }}
        ></TextInput>
        <TextInput
          style={styles.input}
          placeholder="Gym phone"
          keyboardType="phone-pad"
          value={gymPhone}
          onChangeText={(value) => {
            setGymPhone(value);
          }}
        ></TextInput>
        <Button
          title="Create Gym"
          onPress={() => {
            addGymToDB();
          }}
        ></Button>
        <hr />
        <Text style={{ textAlign: "center", marginBottom: 32 }}>
          Add owner details
        </Text>
        <Text style={{ textAlign: "center", marginBottom: 14 }}>
          {ownerMessage}
        </Text>
        <TextInput
          style={styles.input}
          placeholder="Owner name"
          value={ownerName}
          onChangeText={(value) => {
            setOwnerName(value);
          }}
        ></TextInput>
        <TextInput
          style={styles.input}
          placeholder="Owner phone"
          keyboardType="phone-pad"
          value={ownerPhone}
          onChangeText={(value) => {
            setOwnerPhone(value);
          }}
        ></TextInput>
        <TextInput
          style={styles.input}
          placeholder="Owner email"
          keyboardType="email-address"
          value={ownerEmail}
          onChangeText={(value) => {
            setOwnerEmail(value);
          }}
        ></TextInput>
        <TextInput
          style={styles.input}
          placeholder="Owner password"
          secureTextEntry="true"
          value={ownerPassword}
          onChangeText={(value) => {
            setOwnerPassword(value);
          }}
        ></TextInput>
        <Button
          title="Add Gym Owner"
          onPress={() => {
            addUserToDB();
          }}
        ></Button>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  input: {
    paddingHorizontal: 14,
    paddingVertical: 10,
    backgroundColor: "#F5F7F7",
    borderColor: "#DCDEE7",
    borderWidth: 1.5,
    borderRadius: 8,
    marginBottom: 14,
  },
});
