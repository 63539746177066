import { View, Text, TextInput, Button } from "react-native";
import React, { useEffect, useState } from "react";
import { SafeAreaView } from "react-native-web";
import { auth, db } from "../../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  let loginElement;

  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigation.navigate("Dashboard");
      }
    });
    return unsubscribe;
  });

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential.user.uid);
        getDocs(collection(db, "heyagain_users")).then((users) => {
          users.forEach((doc) => {
            if (doc.id == userCredential.user.uid) {
              console.log(doc.data());
            }
          });
        });
      })
      .catch((error) => {
        setLoginMessage(error.message);
        console.log(error.message);
      });
  };
  if (loginMessage.length > 0) {
    loginElement = <Text>{loginMessage}</Text>;
  }

  return (
    <SafeAreaView>
      <View
        style={{
          maxWidth: 720,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 128,
          borderRadius: 6,
          backgroundColor: "#ffffff",
          paddingHorizontal: 32,
          paddingVertical: 24,
        }}
      >
        <Text style={{ marginBottom: 24 }}>Please, login </Text>
        {loginElement}
        <TextInput
          style={{
            paddingHorizontal: 14,
            paddingVertical: 10,
            background: "#F5F7F7",
            borderColor: "#DCDEE7",
            borderWidth: 1.5,
            borderRadius: 8,
            marginBottom: 14,
          }}
          value={email}
          onChangeText={(text) => setEmail(text)}
          placeholder="E-mail"
          keyboardType="email-address"
        ></TextInput>
        <TextInput
          style={{
            paddingHorizontal: 14,
            paddingVertical: 10,
            background: "#F5F7F7",
            borderColor: "#DCDEE7",
            borderWidth: 1.5,
            borderRadius: 8,
            marginBottom: 14,
          }}
          value={password}
          onChangeText={(password) => setPassword(password)}
          placeholder="Password"
          secureTextEntry="true"
        ></TextInput>
        <Button
          title="Login"
          onPress={() => {
            handleSignIn();
          }}
        ></Button>
      </View>
    </SafeAreaView>
  );
}
